import React, { useEffect, useState, useRef } from 'react';
import './styles.css';
import { callApis ,callGetApis, callFetchApis} from '../../helper/fetching';
import axios from "axios";
import * as moment from "moment";

const UserDetailsComponent = () => {
    const _url="https://api-univ.sitare.org/"
    const [applicantPhotoUploaded, setApplicantPhotoUploaded] = useState(false);
    const fileInputRef = useRef(null);

    const [fields, setFields] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        email: "",
        coachingBy: "Others",
        coachingByOthers: "",
        familyIncome: "",
        board10: "",
        overall10: "",
        board12: "",
        overall12: "",
        jeeMainsS1: "",
        // jeeMainsS1All: "",
        jeeMainsS1Physics: "",
        jeeMainsS1Chemistry: "",
        jeeMainsS1Math: "",
        jeeMainsS2: "",
        jeeMainsS2All: "",
        jeeMainsS2Physics: "",
        jeeMainsS2Chemistry: "",
        jeeMainsS2Math: "",
        attachments: []
    })
    const [errorMessage, setErrorMessage] = useState('');
    const [file, setFile] = useState(null);
    const isValidFileType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        return allowedTypes.includes(file.type);
      };

    const handleDownload = async (type) => {
        let mobile = localStorage.getItem('currentuser')
        let aadhar = localStorage.getItem('aadhar')
        // Fetch the image URL from the server
        const response = await fetch(`${_url}api/request/file/${mobile}/${aadhar}/${type}`)
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `DOC${Math.floor(Math.random() * 100000000 + 1)}`
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);  
      };
    
    async function deleteDoc(val) {
        let mobile = localStorage.getItem('currentuser')
        let aadhar = localStorage.getItem('aadhar')
        const params = {
          files: val.files.map((item) => item.file),
          attachment_id: val._id,
          mobile: mobile,
          aadhar: aadhar,
        };
        
        let res = await callFetchApis(`api/request/${mobile}/${aadhar}`, params, 'DELETE')
        if (val && val.type === 'Applicant_Photo'){
            setApplicantPhotoUploaded(false)
        }
        console.log('Response: ', res);
        if (res.data && res.data.attachments.length){
            setFields({...fields, attachments: res.data.attachments})
        } else {
          setFields({...fields, attachments: []})
        }
        setTimeout(() => {
            fileInputRef.current.click()    
        }, 2000);
        
    }

    async function updateStudent(dropped_file, fileType) {
        let mobile = localStorage.getItem('currentuser')
        let aadhar = localStorage.getItem('aadhar')
        let img;
        if (dropped_file){
            // console.log('DROPEED: ', dropped_file);
        const formData = new FormData();
        // formData.append("userImage", dropped_file);
        // formData.append("mobile", mobile);
        // formData.append("aadhar", aadhar);
        // formData.append("type", fileType);
        formData.append('file', dropped_file)
        formData.append('type',fileType);
        // let studentData = {
        //     mobile: mobile,
        //     aadhar: aadhar,
        //     type: fileType,
        //     locations: img,
        //   };
        //   console.log('BEOFRE FIELDS: ', fields);

        await axios
      .post(`${_url}api/request/update/${mobile}/${aadhar}`, formData)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.attachments.length){
            setFields({...fields, attachments: res.data.data.attachments})
          }
      });
        
        }
      }

    async function onDrop(dropped_file, fileType) {
        setFile('')
        setErrorMessage('')
        const file = dropped_file;
        const maxSizeMB = 10;
        
        if (file && file.size > maxSizeMB * 1024 * 1024) { // Convert MB to bytes
            alert('File size exceeds the maximum limit of ' + maxSizeMB + 'MB');
            file.value = ''; // Clear the file input
            // Reset file input
            return;
        }
        else if (!isValidFileType(dropped_file)) {
            alert('Selected file type of ' + dropped_file.type + ' is not allowed.');
            // Reset file input
            return;
        } else {
            if (fileType === 'Applicant_Photo') setApplicantPhotoUploaded(true)
            setFile(dropped_file.name);
            await updateStudent(dropped_file,fileType)
        }
      }

    const handleChange = (id, event) => {
        let value = event.target.value;
        setFields(prevFields => ({
            ...prevFields,
            [id]: value
        }));
    };

    const handleSubmit = async (event) => {
        let mobile = localStorage.getItem('currentuser')
        let aadhar = localStorage.getItem('aadhar')
        event.preventDefault();
        console.log('FILEDS: ', fields);
        if (!applicantPhotoUploaded) {
            // Display an error message or prevent form submission
            alert("Please upload the Applicant's Photo");
            return;
        }
        let res = await callApis(`api/request/user/${mobile}/${aadhar}`, fields, 'PUT')
        if (res.message === "User updated successfully") {
            // setFields(res._user)
            // alert(res.message)
            localStorage.clear();
            window.location.href = 'https://sitare.org/univ/thank-you/'
        }
    };
    const getData = async () => {
        let mobile = localStorage.getItem('currentuser')
        let aadhar = localStorage.getItem('aadhar')
        
        if (mobile === null || mobile === '' || aadhar === null || aadhar === '') window.location.href = 'https://admissions.sitare.org/'
        let res = await callGetApis(`api/request/user/${mobile}/${aadhar}`, {}, 'GET')
        if (res.message === "User fetched successfully") {
          setFields(res._user)
          const attachments = res._user?.attachments || [];
            // Check if any attachment has type "Applicant_Photo" and files array is not empty
            const isApplicantPhotoUploaded = attachments.some(attachment => attachment.type === "Applicant_Photo" && attachment.files.length > 0);
            setApplicantPhotoUploaded(isApplicantPhotoUploaded)
        }
    }

    
    useEffect(() => {
        getData()
    }, [])

    return (
        <>
        <div className="user-container">
            <form onSubmit={handleSubmit} className="user-form">
              <div style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
              <img alt="Sitare-logo" style={{height: "120px"}} src="https://sitare.org/univ/assets/WebP/Sitare-University-Logo.webp"></img>
              </div>
              <div className="header-message">
                <span>For questions, please contact us at <a href="mailto:university@sitare.org">university@sitare.org</a> or by WhatsApp at <a href="https://wa.me/+917849910085">78499 10085</a>.</span>
                <br></br>
                <br></br>
                <span>* indicates a required field. We encourage you to fill as many other fields as you can.</span>
              </div>
                <h2>Candidate Details</h2>
                <div className="row">
                    <div className="column">
                        <label>First Name*</label>
                        <input type="text" required value={fields?.firstName} onChange={(e) => handleChange('firstName', e)} className="input-field-user" />
                    </div>
                    <div className="column">
                        <label>Middle Name</label>
                        <input type="text" value={fields?.middleName} onChange={(e) => handleChange('middleName', e)} className="input-field-user" />
                    </div>
                    <div className="column">
                        <label>Last  Name*</label>
                        <input type="text" required value={fields?.lastName} onChange={(e) => (handleChange('lastName', e))} className="input-field-user" />
                    </div>
                    <div className="column">
                        <label>Date of Birth*</label>
                        <input type="date" required value={moment(fields?.dob).format("yyyy-MM-DD")} onChange={(e) => (handleChange('dob', e))} className="input-field-user" />
                    </div>
                    <div className="column">
                        <label>Gender*</label>
                        <select className="input-field-user" required value={fields?.gender} onChange={(e) => (handleChange('gender', e))}>
                            <option key={'Male'} value={'Male'}>Male</option>
                            <option key={'Female'} value={'Female'}>Female</option>
                            <option key={'Unspecified'} value={'Unspecified'}>Unspecified</option>
                        </select>
                    </div>
                    <div className="column">
                        <label>Email</label>
                        <input type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" value={fields?.email} onChange={(e) => (handleChange('email', e))} className="input-field-user" />
                    </div>
                    <div className="column">
                        <label>Family Income* (per MONTH)</label>
                        <input type="tel" required maxLength={8} value={fields?.familyIncome} onChange={(e) => (handleChange('familyIncome', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}/>
                    </div>
                    <div className="column">
                        <label>Coaching By</label>
                        <select className="input-field-user" required value={fields?.coachingBy} onChange={(e) => (handleChange('coachingBy', e))}>
                            <option key={'Dakshana-Foundation'} value={'Dakshana Foundation'}>Dakshana Foundation</option>
                            <option key={'Physics-Wallah'} value={'Physics Wallah'}>Physics Wallah</option>
                            <option key={'Avanti-Fellows'} value={'Avanti Fellows'}>Avanti Fellows</option>
                            <option key={'Ex-Navodayan'} value={'Ex-Navodayan'}>Ex-Navodayan</option>
                            <option key={'CSRL'} value={'CSRL'}>CSRL</option>
                            <option key={'Top-Neurons'} value={'Top Neurons'}>Top Neurons</option>
                            <option key={'Others'} value={'Others'}>Others (Please Specify)</option>
                        </select>
                    </div>
                    <div className="column">
                        <label>Please Specify (if coaching by others)</label>
                        <input 
                            type="text" 
                            value={fields?.coachingByOthers} 
                            onChange={(e) => handleChange('coachingByOthers', e)} 
                            className="input-field-user" 
                        />
                    </div>
                    </div>
                    
                    <h2>10th Class</h2>
                    <div className="row">
                    <div className="column">
                        <label>10th Board* (e.g. CBSE, UP Board, Bihar Board, etc.)</label>
                        <input 
                          type="text" 
                          required 
                          value={fields?.board10} 
                          onChange={(e) => handleChange('board10', e)} 
                          className="input-field-user" 
                        />
                    </div>
                    <div className="column">
                        <label>10th Overall Percentage*</label>
                        <input type="tel" required maxLength={5} value={fields?.overall10} onChange={(e) => (handleChange('overall10', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}/>

                    </div>
                    </div>
                    <h2>12th Class</h2>
                    <div className="row">
                    <div className="column">
                        <label>12th Board* (e.g. CBSE, UP Board, Bihar Board, etc.)</label>
                        <input 
                          type="text" 
                          required 
                          value={fields?.board12} 
                          onChange={(e) => handleChange('board12', e)} 
                          className="input-field-user" 
                        />

                    </div>
                    <div className="column">
                        <label>12th Overall Percentage (if available)</label>
                        <input type="tel" maxLength={5} value={fields?.overall12} onChange={(e) => (handleChange('overall12', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}/>
                    </div>
                    </div>
                    <h2>JEE Mains Session One</h2>
                    <div className="row">
                    <div className="column">
                        <label>JEE Mains Session One Total NTA Score*</label>
                        <input type="tel" required maxLength={5} value={fields?.jeeMainsS1} onChange={(e) => (handleChange('jeeMainsS1', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session One Physics*</label>
                        <input type="tel" required maxLength={5} value={fields?.jeeMainsS1Physics} onChange={(e) => (handleChange('jeeMainsS1Physics', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session One Chemistry*</label>
                        <input type="tel" required maxLength={5} value={fields?.jeeMainsS1Chemistry} onChange={(e) => (handleChange('jeeMainsS1Chemistry', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session One Math*</label>
                        <input type="tel" required maxLength={5} value={fields?.jeeMainsS1Math} onChange={(e) => (handleChange('jeeMainsS1Math', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    </div>
                    <h2>JEE Mains Session Two</h2>
                    <div className="row">

                    <div className="column">
                        <label>JEE Mains Session Two Total NTA Score</label>
                        <input type="tel" maxLength={5} value={fields?.jeeMainsS2} onChange={(e) => (handleChange('jeeMainsS2', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session Two All India CRL</label>
                        <input type="tel" maxLength={6} value={fields?.jeeMainsS2All} onChange={(e) => (handleChange('jeeMainsS2All', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session Two Physics</label>
                        <input type="tel" maxLength={5} value={fields?.jeeMainsS2Physics} onChange={(e) => (handleChange('jeeMainsS2Physics', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session Two Chemistry</label>
                        <input type="tel" maxLength={5} value={fields?.jeeMainsS2Chemistry} onChange={(e) => (handleChange('jeeMainsS2Chemistry', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    <div className="column">
                        <label>JEE Mains Session Two Math</label>
                        <input type="tel" maxLength={5} value={fields?.jeeMainsS2Math} onChange={(e) => (handleChange('jeeMainsS2Math', e))} className="input-field-user" 
                        onKeyDown={(e) => {
                            if (
                                !(e.keyCode >= 48 && e.keyCode <= 57) && // Numeric keys
                                e.keyCode !== 8 && // Backspace
                                e.keyCode !== 46 && // Delete
                                e.keyCode !== 190 && // Period
                                e.keyCode !== 9 && // Tab
                                e.keyCode !== 37 && // Left arrow
                                e.keyCode !== 39 // Right arrow
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                    </div>
                    </div>
      <h2>Uploads:</h2>
      <div className="" style={{width: "100%"}}>
      
        <div className="column">
        <table>
        <thead>
  <tr>
    <th>S. No.</th>
    <th>Type</th>
    <th>Choose File</th>
    <th>Action</th>
  </tr>
</thead>
<tbody>
  {[
    { display: "Applicant's Photo*", value: "Applicant_Photo" },
    { display: "Photo or PDF of Aadhar", value: "Aadhar_Card", additionalText: "(NO password-protected file please)" },
    { display: "Parent's Income Certificate", value: "Parents_Income" },
    { display: "10th Marksheet", value: "Marksheet_10" },
    { display: "12th Marksheet", value: "Marksheet_12" },
    { display: "JEE Mains Marksheet", value: "Jee_Mains" },
  ].map((option, index) => (
    <tr key={option.value}>
      <td>{index + 1}.</td>
      <td>{option.display}
        {option.additionalText && (
          <div className="additional-text">{option.additionalText}</div>
        )}
      </td>
      <td>
        {fields.attachments &&
          fields.attachments.every(
            (attachment) => attachment.type !== option.value
          ) && (
              <div>
            <input
              type="file"
              ref={fileInputRef}
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={(e) => onDrop(e.target.files[0], option.value)}
              value={file ? file.name : ''}
            />
            </div>
          )}
      </td>
      <td>
        {fields.attachments &&
          fields.attachments.map((attachment) => {
            if (attachment.type === option.value) {
              return (
                <div key={attachment.type + index}>
                  <button
                    type="button"
                    style={{ marginRight: "5px", cursor:"pointer" }}
                    onClick={() => handleDownload(option.value)}
                    className="link-button"
                  >
                    Download
                  </button>
                  <button type="button" style={{cursor:"pointer"}} onClick={() => deleteDoc(attachment)} className="link-button">
                    Update
                  </button>
                </div>
              );
            }
            return null;
          })}
      </td>
    </tr>
  ))}
</tbody>
</table>
            </div>
            </div>
        <div className="error-div">
      {/* Your JSX */}
      {errorMessage && <p>{errorMessage}</p>}
    </div>

        <div style={{ textAlign: "center", paddingTop: "20px" }}>
          <button type="submit" style={{cursor:"pointer"}} className="submit-button">Submit</button>
        </div>
        </form>
    </div>
        </>
    );
};

export default UserDetailsComponent;