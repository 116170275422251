import * as React from "react";
import { useRoutes } from "react-router-dom";
import UserDetailsComponent from "./pages/userdetails";
import UserDetailsVerificationComponent from "./pages/userverificationdetails";
import LoginComponent from "./pages/login";
import LoginVerificationComponent from "./pages/loginverification";
import ThankYouComponent from "./pages/thankyou";

function Route() {
  let element = useRoutes([
    // { path: "", element: <LoginComponent /> },
    // { path: "verification", element: <LoginVerificationComponent /> },
    // { path: "user-details", element: <UserDetailsComponent /> },
    // { path: "user-details-verification", element: <UserDetailsVerificationComponent /> },
    { path:"*", element: <ThankYouComponent /> },
  ]);

  return element;
}
const App = () => {
  return (<>
    <Route />
  </>)
}
export default App
