import React from 'react';
import './style.css'; 

const ThankYouPage = () => {
//   render() {
    return (
      <div className="bg_thankyou">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="thankyou_content">
                <img alt="thank you" style={{ height: "80px" }} src="https://sitare.org/univ/assets/WebP/Sitare-University-Logo.webp" />
                <div style={{ paddingTop: "30px" }}>
                  <h4>Thank you for your interest in Sitare University. Admissions for 2024-2025 academic year are now closed. Admissions for the next academic year will open in May 2025. Please visit <a href="https://sitare.org/univ" target='_blank'>sitare.org/univ</a> to learn more about us.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default ThankYouPage;